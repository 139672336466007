import React, { FC } from 'react'
import { styled } from '@sans-souci/styles'
import { SectionFocusProps } from '@sans-souci/models'
import { Link, Media, SectionWrap } from '@sans-souci/components'

const Root = styled('div', {
  $lin: {
    paddingTop: 'XXL',
    paddingBottom: 'XXL',
  },
  variants: {
    darkTheme: {
      true: {
        background: '$black',
        color: '$beige',
      },
      false: {},
    },
  },
})

const ContentWrap = styled('div', {
  display: 'grid',
  gridAutoFlow: 'row',
  gridRowGap: '$20',
  maxWidth: '$maxWidthS',
  paddingBottom: '30px',
  $lin: {
    paddingLeft: 'S',
    paddingRight: 'S',
  },
})

const Title = styled('h2', {
  $projectFont: 'heading03',
})

const Description = styled('p', {
  $projectFont: 'body01',
})

const StyledLink = styled(Link, {
  width: 'fit-content',
})

export const SectionFocus: FC<SectionFocusProps> = ({
  title,
  description,
  media,
  ctaLink,
  priority,
  darkTheme = false,
  _key,
}) => {
  return (
    <SectionWrap
      type={'withBorders'}
      theme={darkTheme ? 'dark' : 'light'}
      id={_key}
    >
      <Root darkTheme={darkTheme}>
        <ContentWrap>
          {title && <Title>{title}</Title>}
          {description && <Description>{description}</Description>}
          {ctaLink && ctaLink.title && (
            <StyledLink appearance={'ButtonText'} {...ctaLink.payload}>
              {ctaLink.title}
            </StyledLink>
          )}
        </ContentWrap>
        {media && (
          <Media {...media} layout={'responsive'} priority={priority} />
        )}
      </Root>
    </SectionWrap>
  )
}
